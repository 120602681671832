import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import LandingBackground from '../../containerElements/Backgrounds/LandingBackground';
import './LandingPage.scss';
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import WelcomeAnimation from "../../PageAnimations/WelcomeAnimation";

const LandingPage = () => {
    const { ref, inView } = useInView({
        threshold: 0.75,
        triggerOnce: false
    });
    const { t } = useTranslation();
    const welcomeHeadline = t('landingPage.welcome');

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const march23rd = new Date(currentYear, 2, 23);
    const age = currentDate >= march23rd ? currentYear - 2001 : currentYear - 2002;
    let aboutMeText = t('landingPage.aboutMeText');
    aboutMeText = aboutMeText.replace('age', age);

    const [hasVisitedBefore, setHasVisitedBefore] = useState(
        sessionStorage.getItem('hasVisitedBefore') === 'true'
    );

    const typewriterRef = useRef(null);

    useEffect(() => {
        const spans = document.querySelectorAll('.aboutMe-wrapper h1 span, .aboutMe-wrapper p span');
        spans.forEach((span, index) => {
            span.style.animation = inView
                ? `flyIn 1s ease forwards ${index * 0.025}s`
                : `flyOut 0.5s ease forwards ${index * 0.025}s`;
        });
    }, [inView]);

    useLayoutEffect(() => {
        if (!hasVisitedBefore) {
            const timeout = setTimeout(() => {
                setHasVisitedBefore(true);
                sessionStorage.setItem('hasVisitedBefore', 'true');
                initializeTypewriter();
            }, 4000);

            return () => clearTimeout(timeout);
        } else {
            initializeTypewriter();
        }
    }, [hasVisitedBefore]);

    const initializeTypewriter = () => {
        const elements = typewriterRef.current.getElementsByClassName('typewriter');
        for (let i = 0; i < elements.length; i++) {
            const toRotate = elements[i].getAttribute('datatype');
            const period = elements[i].getAttribute('data-period');
            if (toRotate) {
                new TxtType(elements[i], JSON.parse(toRotate), period);
            }
        }
    };

    return (
        <div ref={ref}>
            <LandingBackground inView={inView} />
            {!hasVisitedBefore && (
                <WelcomeAnimation />
            )}
            <div className="landingPage-wrapper" ref={typewriterRef}>
                <div className="aboutMe-wrapper">
                    <h1>
                        {welcomeHeadline.split('').map((char, idx) => (
                            <span className={inView ? "" : "fade-out"} key={idx}>
                                {char.trim() !== '' ? char : '\u00A0'}
                            </span>
                        ))}
                    </h1>
                    <p>
                        {aboutMeText.split(' ').map((word, idx) => (
                            <span key={idx}>
                                {word.split('').map((char, charIdx) => (
                                    <span className={inView ? "" : "fade-out"}
                                          key={charIdx}>
                                        {char}
                                    </span>
                                ))}
                                &nbsp;
                            </span>
                        ))}
                    </p>
                </div>
                <div className={`typing-wrapper ${inView ? 'fade-in' : 'fade-out'}`}>
                    <p className="typewriter" data-period="2000" datatype='["Full-Stack Developer", "Java and JavaScript", "Spring and React", "Anime Fan", "Gym Addicted"]'>
                        <span className="wrap"></span>
                    </p>
                </div>
            </div>
        </div>
    );
};

function TxtType(el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 1500;
    this.txt = '';
    this.isDeleting = false;

    this.tick();
}

TxtType.prototype.tick = function () {
    const i = this.loopNum % this.toRotate.length;
    const fullTxt = this.toRotate[i];

    if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

    const that = this;
    let delta = 200 - Math.random() * 100;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
    }

    setTimeout(function () {
        that.tick();
    }, delta);
};

export default LandingPage;
